import COS from 'cos-js-sdk-v5'
import ajax from './ajax'

const config = {
  Bucket: 'hxclass-1308496679',
  Region: 'ap-beijing'
}


// 文件存储地址参考文档

/*  注释：
* 带** 为父级文件，不可直接当存储地址，需要存储到该父级下的子分类文件夹
* 带## 为需要带日期的文件，即获取临时密钥的isDate值为1
*
* */

/*
  template/               **所有模板类（父级文件夹）
  template/certificate/	  	证书模板
  template/course/	       	课程模板
  template/exam/			      考试模板
  template/user/		      	用户批量导入模板	

  certificate/	-		      **所有证书类（父级文件夹）	
  certificate/course/     ##课程学习证书（按日期分）
  certificate/exam/		    ##考试证书（按日期分）

  invoice/	-		          ##发票（按日期）  

  ueditor/			          **富文本、课程类展示(分日期)（父级文件夹）	
  ueditor/index/			      导航网站
  ueditor/product/        ##商品介绍相关（按日期）
  ueditor/banner/			      图片banner ++
  ueditor/article/	      ##文章资讯，课程介绍中的富文本（按日期）++
  
  user/				            **用户上传类（父级文件夹）	
  user/homework/       	  ##家庭作业（按日期）++
  user/certificate/    	  ##个人上传证书资质，机构资质营业执照（按日期分）++
  user/signup/         	  ##考试报名照片（按日期）++
  user/transaction/    	  ##对公转账（包括机构个人）（按日期）++
  user/head/			          头像++
  user/feedback/	     		  意见反馈
**/



// 上传到腾讯云cos
/*
* file 选取的文件，
* bucket 文件存放在云存储的地址
* isDate 上传文件地址是否需要分日期，0.不需要  1.需要
* fileCallback 文件上传过程中返回上传速度、进度以及文件名的方法，
* callback 文件上传成功后的回调方法，
*
* */
export function uploadObject(file, bucket, fileCallback, callback) {

  let isDate = 0
  let bucketList = [ 'certificate/course/', 'certificate/exam/', 'invoice/', 'ueditor/product/', 'ueditor/article/', 'user/homework/', 'user/certificate/', 'user/signup/', 'user/transaction/' ]  // 存放需要分日期的文件路径
  if(bucketList.indexOf(bucket) != -1){
    isDate = 1
  }
  /*
   1.获取临时秘钥data
   2.初始化
   3.判断文件大小 是否需要分片上传
   */

  let fileName = file.name || ""
  const origin_file_name = fileName.split(".").slice(0, fileName.split(".").length - 1).join('.') // 获取文件名称

  // 1.获取临时秘钥data
  ajax({
    url: '/hxclass-pc/cos/get-credential-dynamic',
    params: {
      fileName: file.name,
      uri: bucket,
      type: isDate
    }
  }).then(res => {


    const data = res.data
    var credentials = data && data.credentials

    // 2.cos初始化
    var cos = new COS({
      getAuthorization: (options, callback) => {
        callback({
          TmpSecretId: credentials.tmpSecretId,
          TmpSecretKey: credentials.tmpSecretKey,
          XCosSecurityToken: credentials.sessionToken,
          StartTime: data.startTime,
          ExpiredTime: data.expiredTime,
          expiration: data.expiration,
          requestId: data.requestId,
        })
      },
    })


    // 3.判断文件大小 是否需要分片上传
    let size = file.size
    if (size / (1024 * 1024) < 5) { // 文件小于5M走普通上传
      cos.putObject(
        {
          Bucket: config.Bucket, // 存储桶名称
          Region: config.Region, // 存储桶所在地域，必须字段
          Key: data.key, // 文件名称
          StorageClass: 'STANDARD',
          Body: file, // 上传文件对象
          // onHashProgress: (progressData) => {
          //   console.log('校验中', JSON.stringify(progressData))
          // },
          onProgress: (progressData) => {
            const percent = parseInt(progressData.percent * 10000) / 100;
            const speed = parseInt((progressData.speed / 1024 / 1024) * 100) / 100;
            // console.log('进度：' + percent + '%; 速度：' + speed + 'Mb/s;');
            fileCallback(percent, speed, origin_file_name)
          },
        },
        (err, res) => {
          if (err) {
            // Message({ message: '文件上传失败,请重新上传', type: 'error' })
            let fileUrl = null
            callback(fileUrl, origin_file_name)
          } else {
            let fileUrl = data.host + data.key
            callback(fileUrl, origin_file_name) // 返回文件链接地址和视频的原始名称 上传完成后的回调
          }
        }
      )
    } else {
      // 上传分块
      cos.sliceUploadFile(
        {
          Bucket: config.Bucket, // 存储桶名称
          Region: config.Region, // 存储桶所在地域，必须字段
          Key: data.key /* 必须 */,
          Body: file,
          onTaskReady: (taskId) => {
          },
          // onHashProgress: (progressData) => {
          //     /* 非必须 */
          //     // console.log(JSON.stringify(progressData))
          // },
          onProgress: function (progressData) {
            const percent = parseInt(progressData.percent * 10000) / 100;
            const speed = parseInt((progressData.speed / 1024 / 1024) * 100) / 100;
            // console.log('进度：' + percent + '%; 速度：' + speed + 'Mb/s;');
            fileCallback(percent, speed, origin_file_name)
          },
        },
        (err, res) => {
          if (err) {
            // Message({ message: '文件上传失败,请重新上传', type: 'error' })
            let fileUrl = null
            callback(fileUrl, origin_file_name)
          } else {
            let fileUrl = data.host + data.key
            callback(fileUrl, origin_file_name) // 返回文件链接地址和视频的原始名称 上传完成后的回调
          }
        }
      )
    }
  })
}
export default {
  uploadObject
}